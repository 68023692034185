import React from "react"

import { APP_URI } from '../configs';
import {Link} from 'gatsby'

import kart from '../images/troopy-kart.svg'
import think from '../images/troopy-think.svg'
import logo from '../images/quiz-logo.svg'
import nube1 from '../images/nube_1.svg'

import tick from '../images/tick.svg'
import cruz from '../images/cruz.svg'

import { Helmet } from "react-helmet";

import axios from "axios";

import '../scss/quiz.scss'

var questions = [
  {
    question: '¿Cuál de estos es un requisito que los juegos necesitan para considerarse eSport?',
    o1: 'Ser divertido',
    o2: 'Poderse jugar en PC',
    o3: 'Permitir enfrentamiento entre 2 o más jugadores',
    t: 3
  },
  {
    question: '¿Cuál de estos juegos NO es un eSport?',
    o1: 'The Last of Us',
    o2: 'Fortnite',
    o3: 'Counter Strike',
    t: 1
  },
  {
    question: '¿Qué enoja más a los gamers?',
    o1: 'Jugadores tóxicos',
    o2: 'Jugadores "AFK"',
    o3: 'Todas son correctas',
    t: 3
  },
  {
    question: '¿Qué juegos habrá en la Troop Cup?',
    o1: 'CSGO, LOL, COD , Fortnite',
    o2: 'CSGO, LOL, GTA, FIFA',
    o3: 'CSGO, LOL, DOTA, FIFA',
    t: 1
  },
  {
    question: '¿Qué se puede hacer en la plataforma de Troop?',
    o1: 'Streamear',
    o2: 'Jugar juegos',
    o3: 'Buscar jugadores',
    t: 3
  },
  {
    question: '¿Cómo se llama nuestro personaje?',
    o1: 'Troopy',
    o2: 'Tripy',
    o3: 'Trupy',
    t: 1
  },
  {
    question: '¿Cómo les llamamos a los gamers en Troop?',
    o1: 'Troopies',
    o2: 'Gamers',
    o3: 'Troopers',
    t: 3
  },
  {
    question: '¿Dónde será la Troop Cup?',
    o1: 'Movistar Arena',
    o2: 'La Rural',
    o3: 'Hipódromo de San Isidro',
    t: 3
  },
  {
    question: '¿Cuál de estos accesorios viene dentro del Troop Loadout?',
    o1: 'Shaker',
    o2: 'Mouse pad',
    o3: 'Funda de celular',
    t: 1
  },
  {
    question: '¿Sos fan de Troopy?',
    o1: 'Si',
    o2: 'Es el mejor personaje',
    o3: 'Todas son correctas',
    t: 3
  },
]

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export default class quiz extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      question: 0,
      name: '',
      client: false,
      random: 0,
      random2: 0,
      random3: 0,
      points: 0,
      result: [
        false,
        false,
        false
      ]
    };
  }
  
  a = [
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
    ['1', '2', '3'],
  ]

  b = [
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
    shuffle(['1', '2', '3']),
  ]

  makeid = function(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }

  updateName = function(name){
    this.setState({
        name: name,
    });
  }

  componentDidMount() {
    this.setState({
      client: true,
    });
  }

  getRandomInt = function(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  handleNext = async function(option){
    if(this.state.question === 10){
      var isCorrect = questions[this.state.question - 1].t === option;
      var points = this.state.points + isCorrect;
      this.setState({
        question: -1,
      });

      try {
        // ============ Sending to Hubspot ============ //
        const PORTAL_ID = '43778557'; 
        const FORM_ID = 'b428108d-5e19-47a1-b88e-1674796ed802'; 
        await axios.request({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          url: `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`,
          data: JSON.stringify({
            fields: [
              {
                name: 'firstname',
                value: this.state.name,
              },
              {
                name: 'email',
                value: this.makeid(5)+'troop@troop.com',
              },
              {
                name: 'points',
                value: points,
              }
            ]
          }),
        });
      } catch (error) {
        console.log(error);
      }

    } else if(this.state.question === 0){
      if(this.state.name.length >= 4){
        this.setState({
          question: 1,
        });
      }
    } else {
      var isCorrect = questions[this.state.question - 1].t === option;
      var correct = questions[this.state.question - 1].t
      var result = [
        false,
        false,
        false,
      ]
      result[correct] = true;
      
      if(this.state.name.length >= 4){
        this.setState({
          question: this.state.question + 1,
          points: this.state.points + isCorrect,
          result: result
        });
      }
    }
  }

  handleKey = function(e){
    if(e.key == 'Enter'){
      this.handleNext();
    }
  }


  render(){
    return(
    <div className="quiz">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Troop Quiz</title>
                <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="wrapper">
        <img src={nube1} className="nube n1"/>
        <img src={nube1} className="nube n2"/>
        <img src={nube1} className="nube n3"/>
        { (this.state.question === -1) &&
          <div className="container">
            <h1>¡Gracias por Participar!</h1>
          </div>
        }
        { (this.state.question === 0) &&
          <>
          <div className="container">
            <img src={logo} key="a" className={"logo--image"}/>
            <h1>¿Listo para el desafío?</h1>
            <div className="question">
              <p>Tu nombre completo</p>
              <input onKeyDown={(e) => this.handleKey(e)} value={this.state.name} onChange={(e) => this.updateName(e.target.value)} type="text" />
              <button className="start" onClick={() => this.handleNext()}>Empecemos ⮕</button>
            </div>
          </div>
          { this.state.client &&
          <img src={kart} key="A" className={"bottom--image kart"}/>
          }
          </>
        }
        { (this.state.question > 0) &&
          <div className="container">
            <img src={logo} className={"logo--image small"}/>
            <div className="question-number">
              <p>Pregunta</p>
              <h4>{this.state.question}/10</h4>
            </div>
            <h2>{questions[this.state.question - 1].question}</h2>
            <div className="question" key={this.state.question}>
              <div className={"option"}>
              <img src={tick} key={'rta1f' + this.state.question} className={this.state.result[this.a[this.state.question -1][0]] ? 'result true' : 'result false'} />
                <img src={cruz} key={'rta1v' + this.state.question} className={!this.state.result[this.a[this.state.question -1][0]] && this.state.question != 1 ? 'result true' : 'result false'} />
                <button onClick={() => this.handleNext(parseInt(this.a[this.state.question -1][0]))}>1</button>
                <p>{questions[this.state.question - 1]['o'+this.a[this.state.question -1][0]]}</p>
               
              </div>
              <div className={"option"}>
              <img src={tick} key={'rta2f' + this.state.question} className={this.state.result[this.a[this.state.question -1][1]] ? 'result true' : 'result false'} />
                <img src={cruz} key={'rta2v' + this.state.question} className={!this.state.result[this.a[this.state.question -1][1]] && this.state.question != 1  ? 'result true' : 'result false'} />
                <button onClick={() => this.handleNext(parseInt(this.a[this.state.question -1][1]))}>2</button>
                <p>{questions[this.state.question - 1]['o'+this.a[this.state.question -1][1]]}</p>
      
              </div>
              <div className={"option"}>
              <img src={tick} key={'rta3f' + this.state.question} className={this.state.result[this.a[this.state.question -1][2]] ? 'result true' : 'result false'} />
                <img src={cruz} key={'rta3v' + this.state.question} className={!this.state.result[this.a[this.state.question -1][2]] && this.state.question != 1  ? 'result true' : 'result false'} />
                <button onClick={() => this.handleNext(parseInt(this.a[this.state.question -1][2]))}>3</button>
                <p>{questions[this.state.question - 1]['o'+this.a[this.state.question -1][2]]}</p>
                
              </div>
            </div>
            </div>
        }
        { this.state.question != 0 &&
            <img src={think} className={"bottom--image think"}/>
        } 
      </div>
    </div>
    );
  }
}
